#hamburger-button, #header-menu-items li:first-child {
  display: none;
}

#mobile-menu-dark-overlay {
  opacity: 0;
}

#header-menu-bg {
  position: absolute;
  background-color: rgba(255,255,255,1);
  box-shadow: 0px -20px 30px #191919;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.header_container {
  height: 60px;
  background-color: transparent;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  z-index: 10000;
}
.header_container > div {
  height: 100%;
}

#header-menu-items {
  font-size: 12px;
  font-family: var(--acumin-pro);
  font-weight: bold;
  position: relative;
}

#header-menu-items a {
  padding: 10px;
}

#header-menu-items a:hover {
  text-decoration: none;
}

#menu-first-item {
  display: none;
}

#active_tab_indicator {
  position: absolute;
  width: 0px;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #191919;
  border-radius: 2px;
  transition: width 300ms, left 300ms;
}

/* #header-menu-items li a {
  display: inline-block;
  text-align: center;
  width: 100%;
}

#header-menu-items li {
  display: inline-block;
  width: 25%;
} */

.logo {
  width: 150px;
}

/* MEDIA QUERY FOR LARGE DESKTOP */
@media only screen and (max-width:1200px){

}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px){

}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){

  nav#header-menu-items {
    align-self: flex-start !important;
    justify-content: space-around;
    align-content: flex-start;
    align-items: center;
    height: 90%;
  }

  nav > a, nav > div {
    align-self: center;
    text-align: center;
    display: block;
    width: 100%;
    height: 20%;
  }

  #menu-first-item {
    display: block;
  }

  nav > a {
    padding: 30px !important;
  }

  #header-logo-container {
    display: none;
  }

  #header-menu {
    position: fixed;
  }

  #header-menu-items {
    flex-wrap: wrap;
    padding: 0;
    padding-bottom: 10vh;
  }

  #header-menu-bg {
    display: none;
  }

  #header-menu {
    position: fixed;
    padding: 0;
    border-radius: 50%;
    right: 50vw !important;
    transform: perspective(1px) translateX(50%);
    transition: height 300ms, width 300ms, bottom 300ms, background-color 300ms;
  }

  /* Mobile menu TEXT */
  #header-menu a {
    color: white !important;
  }
  
  #header-menu.mobileMenuOpened a {
    display: block;
  }

  #header-menu.mobileMenuClosed a {
    display: none;
  }

  /* Mobile menu BACKGROUND */
  #header-menu.mobileMenuClosed.lightBackground {
    background-color: rgba(255,255,255,0.9);
  }

  #header-menu.mobileMenuClosed.darkBackground {
    background-color: #191919;
  }

  /* Mobile menu PADDING / MARGINS */
  #header-menu.bottom-25px {
    bottom: -25px;
  }
  
  #header-menu.bottom-40px {
    bottom: -40px;
  }

  /* Mobile menu OPEN / CLOSED */
  #header-menu.mobileMenuClosed {
    height: 80px;
    width: 80px;
    overflow: hidden;
    box-shadow: 0px 3px 50px rgba(0,0,0,0.1);
  }

  #header-menu.mobileMenuOpened {
    height: 90vh;
    width: 90vh;
    bottom: -10vh;
    background-color: #191919;
  }

  #menu-first-item {
    z-index: 11000;
    cursor: pointer;
  }

  #header-menu.mobileMenuClosed #menu-first-item {
    margin-top: 5px;
  }

  #header-menu.mobileMenuOpened #menu-first-item {
    margin-top: 10px;
    padding: 10px;
  }

  .header_container {
    top: unset !important;
  }

  #mobile-menu-dark-overlay {
    position: fixed;
    background-color: rgba(255,255,255,0.5);
    opacity: 0;
    height: 0;
    transition: opacity 150ms;
    bottom: 0;
    left: 0;
    z-index: 10000;
  }

  .showOverlay {
    opacity: 1 !important;
    height: 100vh !important;
    width: 100vw !important;
  }
}

/* TODO:
  - Cursor at the top to show / hide
  - When changing section, show underlined
   section in header. animate from left to right
   when changed.

  - Mobile menu A in circle, tap expands menu.
    ** try color swapping
    *** Remove at bottom completely
    **** No darkened background
*/

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px){

}
